// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700');
@import url('https://fonts.googleapis.com/css?family=Droid+Serif:400i');
@import url('http://code.jquery.com/ui/1.10.3/themes/smoothness/jquery-ui.css');
// Variables
@import "variables";
@import "home";
@import "navbar";
@import "footer";
@import "sponsors";
@import "pages";
@import "testimonials";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";