.block {
    padding: 10px 0 40px 0;
}

h4 {
    color: #222;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px;
}

.description {
    font-style: italic;
    font-family: "Droid Serif", serif;
    font-size: 17px;
    margin: 0 50px;
    text-align: center;
    color: #777;
}

.social {
    padding: 40px 0 20px 0;
}

.fa-facebook,
.fa-twitter,
.fa-link,
.fa-linkedin,
.fa-pinterest,
.fa-instagram,
.fa-youtube,
.fa-google-plus,
.fa-external-link-square {
    font-size: 20px;
    color: #333;
    text-decoration: none;
    padding: 10px;
    border: 1px solid #eee;
}

.fa-facebook:hover,
.fa-twitter:hover,
.fa-link:hover,
.fa-linkedin:hover,
.fa-pinterest:hover,
.fa-instagram:hover,
.fa-youtube:hover,
.fa-google-plus:hover,
.fa-external-link-square:hover {
    color: #000;
    text-decoration: none;
}

.quote p {
    font-family: 'Droid Serif', serif;
}

.custom-block {
    margin: 0 0 30px 0;
}

.home-sponsors img,
.home-media-partners img {
    margin: 0 0 20px 0;
    border-radius: 3px;
}

.home-testimonials img {
    border-radius: 3px;
}