.home-hero {
    min-height: 700px;
    height: 700px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    h1 {
        color: #eee;
    }
    p {
        color: #999;
        font-size: 18px;
    }
    .registration-button {
        margin-top: 250px;
    }
}

.home-heading {
    margin: 100px 0 70px 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

.stage-heading {
    font-size: 18px;
}

.gray {
    background: #f2f2f2;
}

.section {
    padding-bottom: 80px;
}