.navbar-default .navbar-nav > li > a {
    text-transform: uppercase;
    font-size: .85em;
    color: #333;
    letter-spacing: 1px
}

.navbar-default {
    border-color: #fff!important;
    margin-bottom: 100px!important;
}

.navbar > .container-fluid .navbar-brand {
    margin-right: 50px;
}

.login-btn {
    padding: 5px 10px !important;
    margin: 10px;
}

.social-icons {
    display: block;
    padding-top: 5px;
}
